// Node Modules
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

// Components
import Layout from '../../components/core/layout';
import SEO from '../../components/core/seo';

// Styles
import { above, colors, space } from '../../style/design-system';

const ConfirmationDefaultPage = ({ className }) => {
  const query = useStaticQuery(graphql`{
    heroImage: file(relativePath: {eq: "home/time-to-be-kind.jpg"}) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, layout: CONSTRAINED, width: 1920)
      }
    }
  }`);

  return (
    <Layout displayStickyButton={false}>
      <SEO title="Confirmation" />
      <div className={className}>
        <div className="image-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-">
                <GatsbyImage alt="It's time to be kind." className="gatsby-image" image={query.heroImage.childImageSharp.gatsbyImageData} objectFit="cover" objectPosition="right top" />
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1 className="mt-9 mb-7">Thank you for joining our care&nbsp;community.</h1>
              <p>We have received your form submission. We&#39;ll be in touch&nbsp;shortly.</p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default styled(ConfirmationDefaultPage)`
  background-color: ${colors.cpb.teal};
  min-height: 84vh;
  color: #fff;
  padding-bottom: ${space.g};
  .image-wrapper {
    background-color: ${colors.cpb.yellow};
  }
  .gatsby-image {
    height: 16rem;
    ${above.sm`
      height: 20rem;
    `}
    ${above.md`
      height: 24rem;
    `}
    ${above.lg`
      height: 28rem;
    `}
  }
`;

ConfirmationDefaultPage.propTypes = {
  className: PropTypes.string.isRequired,
};
